import styled from 'styled-components';

export default function PromoVideo() {
  return (
    <VideoWrapper>
      <iframe
        src="https://www.youtube.com/embed/8Eesg8Oxyc8"
        title="YouTube"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen></iframe>
    </VideoWrapper>
  );
}

const VideoWrapper = styled.div`
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin: 2rem 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
