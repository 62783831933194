import styled from '@emotion/styled';
import { Hidden } from '@mui/material';
import { Box } from '@mui/system';
import { BsChevronDoubleDown } from 'react-icons/bs';

import { navigateTo } from './DropDownMenu';

export default function IntroText({ isEnglish }) {
  return (
    <IntroTextWrapper>
      <span id="intro">
        <Hidden lgDown>
          <BsChevronDoubleDown
            size={40}
            onClick={() => navigateTo('#intro')}
          />
        </Hidden>
      </span>

      <TextWrapper>
        <Hidden lgDown>
          <LeftText>
            {isEnglish
              ? 'More glorious than Gloria Gaynor. More wonderful than Stevie Wonder. Wilder than Wild Cherry. Hotter than Donna Summer. They turn every occasion into a Disco Inferno. They get more respect than Aretha Franklin asked for.'
              : 'Glorieuzer dan Gloria Gaynor. Wonderbaarlijker dan Stevie Wonder. Wilder dan Wild Cherry. Heter dan Donna Summer. Ze veranderen elke gelegenheid in een Disco Inferno. Ze krijgen meer respect dan waar Aretha Franklin om vroeg.'}
          </LeftText>
        </Hidden>

        <MiddleText>
          <h1>Step the Gap</h1>
          <h2>Disco, Soul & Funk Partyband</h2>

          <p>
            {isEnglish
              ? 'A performance by Step the Gap means the difference between a fun night and an unforgettable one! Each show is a musical spectacle featuring all the best the 70s and 80s have to offer.'
              : "Elk optreden van Step the Gap betekent het verschil tussen een leuke en een onvergetelijke avond! Iedere show is een muzikaal schouwspel met al het beste dat de 70's en 80's te bieden hebben."}
          </p>

          <p>
            {isEnglish
              ? 'Step the Gap consists entirely of professional musicians; two beautiful singers, a swinging guitarist, funky bass player, rousing keyboard player, a tight drummer ánd a dazzling horn section with saxophone and trumpet. Together they embody the best of Disco, Soul & Funk and will blow the roof off!'
              : 'Step the Gap bestaat volledig uit professionele muzikanten; twee prachtige zangeressen, een swingende gitarist, funky bassist, opzwepende toetsenist, een strakke drummer én een spetterende blazerssectie met saxofoon en trompet. Samen belichamen ze het mooiste van Disco, Soul & Funk en spelen ze het dak eraf!'}
          </p>

          <h4>Step the Gap - Keeps the Soul alive!</h4>
        </MiddleText>

        <Hidden lgDown>
          <RightText>
            {isEnglish
              ? 'Dollier than Dolly Parton. Cooler than Kool & the Gang. Chicer than Chic. And bigger than the Jackson 5, because there are 8 of them. They transform every outing into a return trip to Funky Town. They contain more elements than Earth, Wind & Fire.'
              : "Doller dan Dolly Parton. Cooler dan Kool & the Gang. Chiquer dan Chic. En groter dan the Jackson 5, want ze zijn met z'n achten. Ze transformeren elk uitje in een retourtje Funky Town. Ze bevatten meer elementen dan Earth, Wind & Fire."}
          </RightText>
        </Hidden>
      </TextWrapper>
    </IntroTextWrapper>
  );
}

const IntroTextWrapper = styled.div`
  margin-top: -10rem;
`;

const TextWrapper = styled(Box)`
  z-index: 10;
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

  @media only screen and (max-width: 599px) {
    padding: 0;
  }

  @media only screen and (max-width: 899px) {
    margin-top: 7rem;
  }
`;

const LeftText = styled.div`
  text-align: right;
  padding: 1rem;
  line-height: 1.5rem;
  font-size: 1.2rem;
  margin-top: 3rem;
  font-family: 'Questrial', sans-serif;
`;

const MiddleText = styled.div`
  border-radius: 0.5rem;
  background-color: rgba(79, 44, 114, 0.6);
  text-align: center;
  padding: 1rem;
  font-size: 1.1rem;

  font-family: 'Questrial', sans-serif;

  h1,
  h2,
  h4 {
    font-family: 'Questrial', sans-serif;
  }
`;

const RightText = styled.div`
  text-align: left;
  padding: 1rem;
  line-height: 1.5rem;
  font-size: 1.2rem;
  margin-top: 3rem;
  font-family: 'Questrial', sans-serif;
`;
