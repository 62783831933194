import { Box, Grid, Hidden } from '@mui/material';

import Footer from './components/Footer';
import GroupPhoto from './components/GroupPhoto';
import { InstagramEmbed } from 'react-social-media-embed';
import IntroText from './components/IntroText';
import NavBar from './components/NavBar';
import PromoVideo from './components/PromoVideo';
import Reviews from './components/Reviews';
import Spotify from './components/Spotify';
import styled from '@emotion/styled';
import { useState } from 'react';

const stepTheGapLogoCropped = require('./source/Step the Gap - Website background.jpg');

// const stepTheGapXL = require('./source/group photos/StG XL 1.jpg');
// const stepTheGapXLLive = require('./source/group photos/StG XL 2.jpg');
const stepTheGapWeb1 = require('./source/group photos/Step the Gap - Web 1.jpg');
const stepTheGapWeb2 = require('./source/group photos/Step the Gap - Web 2.jpg');

const review1 = require('./source/reviews/Review 1.png');
const review2 = require('./source/reviews/Review 2.png');
const review3 = require('./source/reviews/Review 3.png');
const review4 = require('./source/reviews/Review 4.png');

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add('active');
    } else {
      reveals[i].classList.remove('active');
    }
  }
}

window.addEventListener('scroll', reveal);

function App() {
  const [isEnglish, setEnglish] = useState(false);

  return (
    <AppWrapper>
      <div id="home" />
      <NavBar
        isEnglish={isEnglish}
        setEnglish={setEnglish}
      />

      <Hidden lgUp>
        <BlackBorder />
      </Hidden>

      <AppBody container>
        <IntroWrapper
          item
          sm={12}
          md={11}
          lg={11}>
          <Logo
            src={stepTheGapLogoCropped}
            alt="Step the Gap - Logo"
          />

          <IntroText isEnglish={isEnglish} />
        </IntroWrapper>

        <ContentWrapper
          item
          sm={11}
          md={10}
          lg={9}>
          <GroupPhoto
            source={stepTheGapWeb1}
            alt="Step the Gap XL - Live"
          />

          <SocialMediaWrapper>
            <SocialMediaText>
              {isEnglish
                ? 'Follow us on social media!'
                : 'Volg ons op social media!'}
            </SocialMediaText>

            <InstagramEmbed
              url="https://www.instagram.com/stepthegap"
              width={'100%'}
            />
          </SocialMediaWrapper>

          <Hidden smDown>
            <Reviews
              reviewLeft={review1}
              reviewRight={review2}
            />
          </Hidden>

          <div id="promovideo">
            <PromoVideo />
          </div>

          <GroupPhoto
            source={stepTheGapWeb2}
            alt="Step the Gap XL"
          />

          <Hidden smDown>
            <Reviews
              reviewLeft={review4}
              reviewRight={review3}
            />
          </Hidden>

          <div id="repertoire">
            <Spotify />
          </div>
        </ContentWrapper>
      </AppBody>

      <div id="footer" />
      <Footer isEnglish={isEnglish} />
    </AppWrapper>
  );
}

const AppWrapper = styled(Box)`
  --primary: hsl(267, 41%, 31%);
  --secondary: hsl(267, 41%, 41%);
  --tertiary: hsl(267, 41%, 21%);
  --special: hsl(39, 79%, 48%);
  --success: hsl(87, 41%, 41%);
  --warning: hsl(357, 41%, 51%);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
  }

  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
`;

const BlackBorder = styled.div`
  min-height: 4.5rem;
`;

const AppBody = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 0 2rem;

  @media only screen and (max-width: 599px) {
    padding: 0 1rem;
  }
`;

const IntroWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const ContentWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const SocialMediaWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const SocialMediaText = styled.h1`
  margin: 0;
  color: var(--special);
  font-weight: 900;
`;

const Logo = styled.img`
  max-width: 100%;
`;

export default App;
