import { Button, Input } from '@mui/material';
import { useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import styled from '@emotion/styled';

export default function ContactForm({ isEnglish }) {
  const formDefault = { name: '', email: '', message: '' };
  const requiredDefault = {
    name: true,
    email: true,
    message: true,
    captcha: true,
  };

  const requiredMessage = { en: 'required', nl: 'verplicht' };

  const [form, setForm] = useState(formDefault);
  const [required, setRequired] = useState(requiredDefault);
  const [isSuccess, setSuccess] = useState(false);

  const captchaRef = useRef(null);

  function formChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  function showSuccess() {
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }

  const formSubmit = async (e) => {
    e.preventDefault();

    const { name, email, message } = form;
    const token = captchaRef.current.getValue();

    if (!name || !email || !message || !token) {
      setRequired({
        name: !!name,
        email: !!email,
        message: !!message,
        captcha: !!token,
      });

      return;
    }

    try {
      await emailjs.sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_USER_ID
      );
    } catch (error) {
      console.error(error);
    } finally {
      showSuccess();

      setForm(formDefault);
      setRequired(requiredDefault);

      captchaRef.current.reset();
    }
  };

  return (
    <FormWrapper onSubmit={formSubmit}>
      <InputWrapper>
        <StyledInput
          type="text"
          name="name"
          value={form.name}
          onChange={formChange}
          placeholder={isEnglish ? 'name' : 'naam'}
        />

        {!required.name && (
          <RequiredWarning>
            {isEnglish ? requiredMessage.en : requiredMessage.nl}
          </RequiredWarning>
        )}
      </InputWrapper>

      <InputWrapper>
        <StyledInput
          type="text"
          name="email"
          value={form.email}
          onChange={formChange}
          placeholder="email"
        />

        {!required.email && (
          <RequiredWarning>
            {isEnglish ? requiredMessage.en : requiredMessage.nl}
          </RequiredWarning>
        )}
      </InputWrapper>

      <InputWrapper>
        <StyledInput
          multiline={true}
          minRows={6}
          type="text"
          name="message"
          value={form.message}
          onChange={formChange}
          placeholder={isEnglish ? 'question or remark' : 'vraag of opmerking'}
        />

        {!required.message && (
          <RequiredWarning>
            {isEnglish ? requiredMessage.en : requiredMessage.nl}
          </RequiredWarning>
        )}
      </InputWrapper>

      {!isSuccess && (
        <InputWrapper style={{ width: 'fit-content' }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            ref={captchaRef}
          />

          {!required.captcha && (
            <RequiredWarning style={{ width: '100%' }}>
              {isEnglish ? requiredMessage.en : requiredMessage.nl}
            </RequiredWarning>
          )}
        </InputWrapper>
      )}

      {isSuccess && (
        <SuccessWarning>
          {isEnglish ? 'Message sent' : 'Bericht verstuurd'}
        </SuccessWarning>
      )}

      <StyledButton type="submit">Submit</StyledButton>
    </FormWrapper>
  );
}

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInput = styled(Input)`
  background-color: white;
  padding: 0.2rem 1rem;
  width: 90%;
  border-radius: 0.3rem;
  color: var(--tertiary);
`;

const RequiredWarning = styled.h5`
  width: 90%;
  text-align: left;
  padding: 0.2em 0 0 1em;
  color: var(--warning);
  margin: 0;
`;

const SuccessWarning = styled.h3`
  width: 90%;
  color: var(--success);
  padding: 0;
  margin: 0;
`;

const StyledButton = styled(Button)`
  background-color: var(--primary);
  color: white;
  font-weight: bold;
  margin-top: 0.3rem;
`;
