import styled from '@emotion/styled';
import { Grid, Hidden } from '@mui/material';

export default function Reviews({ reviewLeft, reviewRight }) {
  return (
    <ReviewWrapper
      container
      className="reveal">
      <ReviewBody
        item
        md={12}
        lg={6}>
        <Review src={reviewLeft} />
      </ReviewBody>

      <Hidden lgDown>
        <ReviewBody
          item
          md={12}
          lg={6}>
          <Review src={reviewRight} />
        </ReviewBody>
      </Hidden>
    </ReviewWrapper>
  );
}

const ReviewWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
`;

const ReviewBody = styled(Grid)`
  display: flex;
  flex-direction: row;
  padding: 0.3rem;
`;

const Review = styled.img`
  min-height: 10rem;
  border-radius: 0.3rem;
  max-width: 100%;
`;
